<template>
    <div class="attendance-loader">
        <div class="attendance-card">
            <div class="shimmer-wrapper" style="width: 95%;height: 250px">
                <div class="navigator-wrapper">
                    <div style="width: 25px;height: 25px;margin-left: 10px;margin-top: 6px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
                    <div style="width: 50%;display: flex;flex-direction: column;margin-top: 3px;margin-left: 20%;">
                        <div class="shimmer-line shimmer-line-br shimmer-line-80 shimmer-animate mt-10"></div>
                    </div>
                </div>

                <div class="time-box">
                    <div style="margin: 0;width: 48%;height: 30px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50"></div>
                    <div style="margin: 0;width: 48%;" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50"></div>
                </div>
                
                <div class="clock-box">
                    <div class="top">
                        <div style="margin-bottom: 15px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate mt-10"></div>
                    </div>
                    
                    <div class="body">
                        <div style="margin: 0;width: 47%" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50 h-40"></div>
                        <div style="margin: 0;width: 47%" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-30 h-40"></div>
                    </div>
                </div>
            </div>
        </div>

        <div style="width: 100%;display: flex;flex-direction: row;justify-content: space-between">
            <div style="margin: 80px 10px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
            <div style="margin: 80px 10px;width: 70px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
        </div>

        <div style="width: 100%;padding: 10px;margin-top: -80px">
            <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
            <div style="width: 95%;display: flex;flex-direction: row;gap: 40px;margin-top: 10px;justify-content: space-between">
                <div style="width: 50px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div style="width: 20px;height: 20px;margin-top: 3px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
            </div>
            <div style="width: 95%;display: flex;flex-direction: row;gap: 40px;margin-top: 5px;justify-content: space-between">
                <div style="width: 50px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div style="width: 20px;height: 20px;margin-top: 3px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
            </div>
        </div>

        <div style="width: 100%;padding: 10px;margin-top: 0">
            <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
            <div style="width: 95%;display: flex;flex-direction: row;gap: 40px;margin-top: 10px;justify-content: space-between">
                <div style="width: 50px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div style="width: 20px;height: 20px;margin-top: 3px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
            </div>
            <div style="width: 95%;display: flex;flex-direction: row;gap: 40px;margin-top: 5px;justify-content: space-between">
                <div style="width: 50px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div style="width: 20px;height: 20px;margin-top: 3px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
            </div>
        </div>

        <div style="width: 100%;padding: 10px;margin-top: 0">
            <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
            <div style="width: 95%;display: flex;flex-direction: row;gap: 40px;margin-top: 10px;justify-content: space-between">
                <div style="width: 50px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div style="width: 20px;height: 20px;margin-top: 3px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
            </div>
            <div style="width: 95%;display: flex;flex-direction: row;gap: 40px;margin-top: 5px;justify-content: space-between">
                <div style="width: 50px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div style="width: 20px;height: 20px;margin-top: 3px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
            </div>
        </div>

        <div style="width: 100%;padding: 10px;margin-top: 0">
            <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
            <div style="width: 95%;display: flex;flex-direction: row;gap: 40px;margin-top: 10px;justify-content: space-between">
                <div style="width: 50px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div style="width: 20px;height: 20px;margin-top: 3px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
            </div>
            <div style="width: 95%;display: flex;flex-direction: row;gap: 40px;margin-top: 5px;justify-content: space-between">
                <div style="width: 50px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
                <div style="width: 20px;height: 20px;margin-top: 3px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LiveAttendanceLoader'
}
</script>

<style>
.attendance-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 0;
    z-index: 1000;
    overflow: hidden;
}

.attendance-loader .attendance-card{
    position: relative;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #e0e0e0;
    background: #929292;
}

.attendance-loader .attendance-card .navigator-wrapper{
    position:  relative;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: flex; 
    flex-direction: row;
    gap: 20px;
    background-color: #b9b9b9;
    margin-top: 5px;
    margin-left: 3px;
    padding-bottom: 0" class="mt-10
}

.attendance-loader .attendance-card .clock-box{
    position: absolute;
    left: 49%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 130px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    width: 88%;
    height: 130px;
    background: #fff;
    box-shadow: 0 3px 5px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.attendance-loader .attendance-card .clock-box .top{
    width: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
}

.attendance-loader .attendance-card .clock-box .body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 15px
}

.time-box{
    position: absolute;
    top: 110px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px
}
</style>