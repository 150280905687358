<template>
    <navbar :title="this.$route.params.date"></navbar>
    <div style="margin-top: 60px;width: 100%;">
        <div style="position: relative;
                    width: 96%; 
                    height: 80px;
                    margin: 0 auto;
                    background: #ffffff; 
                    border-radius: 15px;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 5px;
                    margin-top: 5px;
                    border: 1px solid var(--border);
                    font-family: Arial, Helvetica, sans-serif;"
                    v-for="attend in attendance" :key="attend.id">
            
            <router-link 
                    style="
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        width: 40px;
                        background: var(--bluecolor);
                        border-top-right-radius: 15px;
                        border-bottom-right-radius: 15px;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 23px;
                        text-decoration: none;"
                        :to="{name: 'detail', params: { id: attend.id}}">
                        <i class="fa-solid fa-circle-info"></i>
            </router-link>

            <div style="position: relative;width: 75px;height: 80px;background: var(--border);border-radius: 10px;overflow: hidden;">
                <img style="width: 100%;height: 100%; object-fit: cover;" :id="'photo' + String(attend.id)" :src="getImageAttendance(attend.photo, attend.id)" alt="">
            </div>

            <div style="position: relative;width: 60%;display: flex;flex-direction: column;margin-left: 15px;text-align: center;">
                <span style="color: var(--gray);font-size: 11pt;">{{ getClocking(attend.clocking) }}</span>
                <span style="margin-top: 8px;font-family: Arial, Helvetica, sans-serif;font-size: 12pt;">{{ String(attend.lok_nm).substring(0, 25) }}<span v-if="String(attend.lok_nm).length > 20">...</span></span>
                
                <span style="position: absolute;
                             bottom: 0;
                             font-size: 18pt;
                             left: 50%;
                             -webkit-transform: translateX(-50%);
                             transform: translateX(-50%);
                             font-family: 'Dosis', sans-serif;
                             color: var(--backcolor);
                             font-weight: bolder;">
                    {{ attend.attendance_time }}
                </span>
            </div>

        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import axios from 'axios';

export default {
    name: 'AttendanceAll',
    components: {
        Navbar,
    },
    data(){
        return {
            attendance: [],
        }
    },
    created(){
        this.getAttendanceDetails()
    },
    methods: {
        async getImageAttendance(filename, id){
            await fetch(this.uri + '/images/attendance/' + filename, {
                method: 'GET',
                headers: {
                    'Authorization': this.$store.getters.GET_AUTH_TOKEN
                }
            }).then((res) => {
                return res.blob()
            }).then((blob) => {
                let url = URL.createObjectURL(blob)
                document.getElementById('photo' + String(id)).src = url
            })
        },
        async getAttendanceDetails(){
            try{ 
                const body = {
                    date: (this.$route.params.date).replace('/', '-').replace('/', '-')
                }

                const { data } = await axios.post('/attendance_list', body, {
                    headers: {
                        'Authorization' : this.$store.getters.GET_AUTH_TOKEN
                    }
                })
                
                this.attendance = data.data
            } catch(e){
                if(e.response.status == 401){
                    this.$store.dispatch("REFRESH")
                    .then(() => {
                        window.location.reload()
                    }).catch(() => {
                        this.$router.push({name: 'login'})
                    })
                } else if(e.response.status == 406) {
                    this.$store.dispatch("LOGOUT")
                    .then(() => {
                        this.$router.push({ path : '/'});
                    }).catch(() => {
                        this.$router.push({ path : '/'});
                    });
                }
            }
        },
        getClocking(value){
            if(value) {
                return "Clock In"
            } else {
                return "Clock Out"
            }
        }
    }
}
</script>

<style>

</style>
