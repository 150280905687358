<template>
    <div class="clockin-loader">
        <div class="clockin-card">
            <div class="shimmer-wrapper" style="width: 95%;height: 50px">
                <div class="navigator-wrapper">
                    <div style="width: 25px;height: 25px;margin-left: 10px;margin-top: 6px" 
                    class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
                    <div style="width: 50%;display: flex;flex-direction: column;margin-top: 3px;margin-left: 20%;">
                        <div class="shimmer-line shimmer-line-br shimmer-line-80 shimmer-animate mt-10"></div>
                    </div>
                </div>
            </div>
        </div>

        <div style="height: 40vh;margin-top: 0" class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></div>
        <div class="clockin-body">
            <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
            <div style="height: 200px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate mt-30"></div>
            <div style="height: 100px" class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate mt-40"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClockinLoader',
}
</script>

<style>
.clockin-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 0;
    z-index: 1000;
    overflow: hidden;
}

.clockin-loader .clockin-card{
    position: relative;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.clockin-loader .clockin-card .navigator-wrapper{
    position:  relative;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: flex; 
    flex-direction: row;
    gap: 20px;
    background-color: #b9b9b9;
    margin-top: 5px;
    margin-left: 3px;
    padding-bottom: 0" class="mt-10
}

.clockin-body{
    position:relative;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 20px;
    padding: 20px;
}
</style>