<template>
    <div class="inbox-loader">
        <div class="inbox-card">
            <div class="shimmer-wrapper" style="width: 95%;height: 50px">
                <div class="navigator-wrapper">
                    <div style="width: 25px;height: 25px;margin-left: 10px;margin-top: 6px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
                    <div style="width: 50%;display: flex;flex-direction: column;margin-top: 3px;margin-left: 20%;">
                        <div class="shimmer-line shimmer-line-br shimmer-line-80 shimmer-animate mt-10"></div>
                    </div>
                </div>
            </div>
        </div>

        <div style="display: flex;flex-direction: row;justify-content: space-between;padding: 20px">
            <div class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-20"></div>
            <div style="width: 80px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-20"></div>
        </div>
    
        <div class="inbox-body">
            <div style="height: 80px;margin: 0" class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></div>
        </div>

        <div class="inbox-body">
            <div style="height: 80px" class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></div>
        </div>

        <div class="inbox-body">
            <div style="height: 80px" class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></div>
        </div>

        <div class="inbox-body">
            <div style="height: 80px" class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></div>
        </div>

        <div class="inbox-body">
            <div style="height: 80px" class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></div>
        </div>

        <div class="inbox-body">
            <div style="height: 80px" class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InboxLoader'
}
</script>

<style>
.inbox-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 0;
    z-index: 1000;
    overflow: hidden;
}

.inbox-loader .inbox-card{
    position: relative;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.inbox-loader .inbox-card .navigator-wrapper{
    position:  relative;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: flex; 
    flex-direction: row;
    gap: 20px;
    background-color: #b9b9b9;
    margin-top: 5px;
    margin-left: 3px;
    padding-bottom: 0" class="mt-10
}

.inbox-loader .inbox-body{
    position:relative;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 auto;
}
</style>