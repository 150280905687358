<template>
    <div class="leave-loader">
        <div class="leave-card">
            <div class="shimmer-wrapper" style="width: 95%;height: 50px">
                <div class="navigator-wrapper">
                    <div style="width: 25px;height: 25px;margin-left: 10px;margin-top: 6px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
                    <div style="width: 50%;display: flex;flex-direction: column;margin-top: 3px;margin-left: 20%;">
                        <div class="shimmer-line shimmer-line-br shimmer-line-80 shimmer-animate mt-10"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="report-wrapper">
            <div style="position: absolute;top: 5px;left: 10px;width: 80px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
            <div style="position: absolute;top: 5px;right: 10px;width: 50px" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-10"></div>
            <div style="position: relative;width: 20%;height: 60%;margin: 0;margin-top: 35px;" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50 h-50"></div>
            <div style="position: relative;width: 20%;height: 60%;margin: 0;margin-top: 35px;" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50 h-50"></div>
            <div style="position: relative;width: 20%;height: 60%;margin: 0;margin-top: 35px;" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50 h-50"></div>
            <div style="position: relative;width: 20%;height: 60%;margin: 0;margin-top: 35px;" class="shimmer-line shimmer-line-br shimmer-line-40 shimmer-animate mt-50 h-50"></div>
        </div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 145px;width: 96%"></div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 15px;width: 96%;height: 92px;"></div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 10px;width: 96%;height: 92px;"></div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 10px;width: 96%;height: 92px;"></div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 10px;width: 96%;height: 92px;"></div>
    </div>
</template>

<script>
export default {
    name: 'LeaveLoader'
}
</script>

<style>
.leave-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 0;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: scroll;
}

.leave-loader .leave-card{
    position: relative;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.leave-loader .leave-card .navigator-wrapper{
    position:  relative;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: flex; 
    flex-direction: row;
    gap: 20px;
    background-color: #b9b9b9;
    margin-top: 5px;
    margin-left: 3px;
    padding-bottom: 0" class="mt-10
}

.leave-loader .report-wrapper{
    position: absolute;
    top: 80px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 96%;
    height: 110px;
    margin: 0 auto;
    border: 1px solid var(--border);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: #b9b9b9;
    gap: 5px;
}
</style>