<template>
  <div style="position: fixed;
              width: 100vw;
              height: 100vh;
              z-index: 100;
              padding:0;
              overflow: hidden;
              top: 0;
              left:0;
              ">
        <div style="position:absolute;
                    width: 100%;
                    height: 100%;
                    margin:0;
                    top:0;
                    left:0;
                    background: var(--screenoverlay)"
                    @click="closeCropping"></div>
        <div style="position: relative;
                    top: 25%;
                    margin: 0 auto;
                    width: 90%;
                    max-width: 360px;
                    /* height: 410px; */
                    background: var(--white);
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    ">

            <div style="position: relative;width: 94%;display: flex;flex-direction: row;flex-wrap: wrap; margin: 0 auto;">
                <div style="width: 80px;height: 80px;border-radius: 50%;overflow: hidden;margin: 10px 0;border: 2px solid var(--bluecolor);">
                    <img :src="destination" alt="" style="margin-bottom: 10px;height: 100%">
                </div>

                <div style="display: flex;flex-direction: column;margin: 10px 10px;">
                    <span style="font-size: 16pt;font-weight: 600;">Employee</span>
                </div>                
            </div>
            
            <div style="position: relative;
                        margin: 0 auto;
                        width: 320px;
                        height:320px;
                        overflow: scroll;
                        padding: 0;
                        display: flex;
                        justify-content: center;">
                    <img ref="image" style="display: block;height: 100%" :src="src" alt="">
            </div>

            <button style="width: 90%;
                           border: none;
                           height: 50px;
                           background: var(--bluecolor);
                           margin: 0 auto;
                           margin-top: 10px;
                           border-radius: 3px;
                           color: var(--white);
                           font-size: 13pt;
                           font-weight: 600;
                           cursor: pointer;
                           margin-bottom: 10px;"
                           @click="crop">
                           Crop
            </button>
        </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs';
export default {
    name: 'CropperVue',
    props: ['src', 'name', 'position'],
    data(){
        return {
            cropper: {},
            destination: {},
            image: '',
        }
    },
    mounted(){
        this.image = this.$refs.image
        this.cropper = new Cropper(this.image, {
            zoomable: false,
            scalable: false,
            movable: true,
            aspectRatio: 1,
            crop: () => {
                const canvas = this.cropper.getCroppedCanvas();
                this.destination = canvas.toDataURL("image/png");
            },
        })
    },
    methods: {
        crop(){
            this.$emit('cropped', this.destination)
        },
        closeCropping(){
            this.$emit('close', false);
        }
    }
}
</script>

<style>

</style>