<template>
    <div class="new-leave-loader">
        <div class="new-leave-card">
            <div class="shimmer-wrapper" style="width: 95%;height: 50px">
                <div class="navigator-wrapper">
                    <div style="width: 25px;height: 25px;margin-left: 10px;margin-top: 6px" class="shimmer-circle shimmer-circle-sm shimmer-animate"></div>
                    <div style="width: 50%;display: flex;flex-direction: column;margin-top: 3px;margin-left: 20%;">
                        <div class="shimmer-line shimmer-line-br shimmer-line-80 shimmer-animate mt-10"></div>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin: 0 auto;margin-top: 50px;" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
        <div style="margin-top: 37px;margin-left:25px;width: 70px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 14px;width: 86%"></div>
        <div style="margin-top: 20px;margin-left:25px;width: 70px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 13px;width: 86%;height: 92px;"></div>
        <div style="margin-top: 33px;margin-left:25px;width: 70px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 14px;width: 86%"></div>
        <div style="display: flex;flex-direction: row;">
            <div style="width: 45%;">
                <div style="margin-top: 33px;margin-left:25px;width: 70px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 14px;width: 86%;margin-left: 27px"></div>
            </div>
            <div style="width: 45%;">
                <div style="margin-top: 33px;margin-left:25px;width: 70px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
                <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 14px;width: 86%;margin-left: 27px;"></div>
            </div>
        </div>
        <div style="margin-top: 33px;margin-left:25px;width: 70px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-40" style="margin: 0 auto;margin-top: 14px;width: 86%"></div>
        <div style="margin-top: 33px;margin-left:25px;width: 70px" class="shimmer-line shimmer-line-br shimmer-line-60 shimmer-animate"></div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-50" style="margin: 0 auto;margin-top: 14px;width: 86%;height: 300px;"></div>
        <div class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate h-50" style="margin: 0 auto;margin-top: 25px;width: 86%;margin-bottom: 20px;"></div>
    </div>
</template>

<script>
export default {
    name: ' NewLeaveLoader',
}
</script>

<style>
.new-leave-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 0;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: scroll;
}

.new-leave-loader .new-leave-card{
    position: relative;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.new-leave-loader .new-leave-card .navigator-wrapper{
    position:  relative;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    display: flex; 
    flex-direction: row;
    gap: 20px;
    background-color: #b9b9b9;
    margin-top: 5px;
    margin-left: 3px;
    padding-bottom: 0" class="mt-10
}

</style>